.App {
  text-align: center;
  background-color:#141413;
  /* 644040*/
  color: #cccccc;
  display: flex;
}

.App-left{
  flex:19%;
  background-color: #341e2f;
}
.App-right{
  flex:19%;
  background-color: #341e2f;
}
.App-center{
  flex:62%;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.8);
  clip-path: inset(0px -30px 0px -30px);
  font-size: 25px;
}
.App-center-subTitle{
  font-size: 25px;
  color: #888888;
}
.App-center-subTitleEmail{
  font-size: 20px;
  color: #888888;
}
.App-center-emailLink{
  color: #888888;
  text-decoration: none;
}
.App-center-parag{
  margin-left: 5%;
  margin-right: 5%;
  color: #888888;
  text-align: left;
}

.App-center-stuffDone{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.App-link {
  text-decoration: none;
}


@media only screen and (max-width: 1024px){
  .App-left{
    flex:0;
  }
  .App-right{
    flex:0;
  }
  .App-center{
    flex: 100%;
    box-shadow: none;
    clip-path: none;
  }
}
